import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { FavouritesType, IBundleFav, IFav, IFetchedFav, IGetLobPayload, IGetLobPayloadV2, ITxnFeePayload, TFavourites } from "../../config/commonInterfaces";
import { createAvailableFeaturesObj } from "../../features/beta/helpers/betaHelper";
import { flattenAndSortBundleFavs, flattenAndSortFavs } from "../../features/favourites/helpers/favouritesHelper";
import { clearStore } from "../actions";

export interface IGlobalStore {
  isLoading: boolean;
  isCustomLoading: boolean;
  athsEvent: any;
  isOnline: boolean;
  betaFeatures: string[] | undefined;
  availableBetaFeatures: Record<string, boolean>;
  fetchBetaFeaturesFailed: boolean;
  historyData: any;
  recentNumberName: any;
  checkLobData: any;
  favouritesData: {
    timeStamp: string;
    [FavouritesType.rechargeAirtimeFav]: IFav[] | undefined;
    [FavouritesType.buyBundleFav]: IBundleFav[] | undefined;
    [FavouritesType.sendMoneyFav]: IFav[] | undefined;
  },
  allFavouritesData: any;
  betaUsers: any;
  apiError: any;
  showIOSScreen: boolean;
  openAddToHomePopUp: boolean;
  mayBeLater: boolean;
  
}

export const globalSliceInitialState: IGlobalStore = {
  isLoading: false,
  isCustomLoading: false,
  athsEvent: {},
  isOnline: true,
  betaFeatures: [],
  availableBetaFeatures: {} as Record<string, boolean>,
  fetchBetaFeaturesFailed: false,
  historyData: {
    timeStamp: ""
  },
  recentNumberName: {},
  checkLobData: {},
  favouritesData: {
    timeStamp: "",
    rechargeAirtimeFav: undefined,
    buyBundleFav: undefined,
    sendMoneyFav: undefined
  },
  allFavouritesData: {},
  betaUsers: {},
  apiError: {
    isApiError: false,
    errorBy: ""
  },
  showIOSScreen: false,
  openAddToHomePopUp: false,
  mayBeLater: false
}

export const globalSlice = createSlice({
  name: "globalStore",
  initialState: globalSliceInitialState,
  reducers: {
    setLoader: (state, action) => {
      state.isLoading = action.payload;
    },
    setCustomLoader: (state, action) => {
      state.isCustomLoading = action.payload;
    },
    saveAthsEvent: (state, action) => {
      state.athsEvent = action.payload;
    },

    callGetTxnFee: (state, action: { payload: ITxnFeePayload, type: string }) => {
    },
    callGetTxnFeeSuccess: (state, action) => {
    },
    callGetTxnFeeFailed: (state, action) => {
    },

    callGetLOB: (state, action: { payload: IGetLobPayload | IGetLobPayloadV2, type: string }) => {
    },
    callGetLOBSuccess: (state, action) => {
    },
    callGetLOBFailed: (state, action) => {
    },
    callProcessTrxn: (state, action) => {

    },
    setOnline: (state, action) => {
      state.isOnline = action.payload;
    },

    callGetRecentContactsRecharge: (state, action) => {

    },

    callGetBetaFeatures: (state, action: { payload: { data: any } }) => {

    },
    callGetBetaFeaturesSuccess: (state, action: { payload: { allBetaFeatures: string[], betaFeatures: any } }) => {
      const availableBetaFeatures = createAvailableFeaturesObj(action.payload.betaFeatures);
      state.availableBetaFeatures = availableBetaFeatures
      state.betaFeatures = action.payload.betaFeatures;
      state.fetchBetaFeaturesFailed = false;
    },

    callGetBetaFeaturesFailed: (state, action) => {
      state.fetchBetaFeaturesFailed = true;
    },

    setUpFeaturesStore: (state, action: { payload: { allBetaFeatures: string[] } }) => {
      const availableFeatures = createAvailableFeaturesObj(action.payload.allBetaFeatures);
      state.availableBetaFeatures = availableFeatures;
      state.betaFeatures = [];
    },

    callGetRecentContactsSendMoney: (state, action) => {

    },
    callGetRecentContactsBundle: (state, action) => {

    },
    setHistoryData: (state, action) => {
      if (!state.historyData) {
        state.historyData = { ...globalSliceInitialState.historyData };
      }

      state.historyData.timeStamp = moment().format();
      state.historyData[action.payload.type] = action.payload.data;
    },
    setRecentNumberName: (state, action) => {
      if (state.recentNumberName) {
        let obj = {
          name: action.payload.data,
          timeStamp: moment().format()
        }
        state.recentNumberName[action.payload.msisdn] = { ...obj };
      }
    },
    setCheckLobData: (state, action) => {
      if (state.checkLobData) {
        let obj = {
          isAirtel: action.payload?.isAirtel,
          isPostpaidNum: action.payload?.isPostpaidNum,
          isBroadband: action.payload?.isBroadband,
          timeStamp: moment().format()
        }
        state.checkLobData[action.payload.msisdn] = { ...obj };
      }
    },
    resetHistoryData: (state, action) => {
      if (state.historyData) {
        state.historyData = {};
        state.historyData["timeStamp"] = "";
      }
    },
    resetCheckLobData: (state, action) => {
      if (state.checkLobData) {
        state.checkLobData = {};
      }
    },
    resetRecentNumberName: (state, action) => {
      if (state.recentNumberName) {
        state.recentNumberName = {};
      }
    },
    callFetchFavourites: (state, action: { payload: { favouritesType: TFavourites, data: any } }) => {
    },
    callFetchFavouritesSuccess: (state, action: { payload: { favouritesType: TFavourites, data: IFetchedFav[] } }) => {
      if (!state.favouritesData) {
        state.favouritesData = { ...globalSliceInitialState.favouritesData };
      }
      const favouritesType = action.payload?.favouritesType;
      let responseData = action.payload?.data
      if (favouritesType === FavouritesType.buyBundleFav) {
        state.favouritesData[favouritesType] = flattenAndSortBundleFavs(responseData);
      }
      else {
        state.favouritesData[favouritesType] = flattenAndSortFavs(responseData);
      }
      state.favouritesData.timeStamp = moment().format();
    },
    callFetchFavouritesFailed: (state) => {
      state.favouritesData.timeStamp = "";
    },
    callAddFavourite: (state, action) => { },
    callDeleteFavourite: (state, action) => {

    },
    callEditFavourite: (state, action) => {

    },
    fetchAllFavourites: (state, action) => {
      state.allFavouritesData = action.payload;
    },
    fetchBetaUsers: (state, action) => {
    },
    fetchBetaUsersSuccess: (state, action) => {
      state.betaUsers = action.payload;
    },
    callProcessTxnV2: (state, action) => { },
    callFetchTransactionFee: (state, action) => { },
    callCheckTransactionLimit: (state, action) => { },
    callAMUserInfo: (state, action) => { },
    setApiError: (state, action) => {
      state.apiError.isApiError = action.payload.isApiError;
      state.apiError.errorBy = action.payload.errorBy;
    },
    updateShowIOSScreen: (state, action) => {
      state.showIOSScreen = action.payload.showIOSScreen;
    },
    updateAddToHomePopUp: (state, action) => {
      state.openAddToHomePopUp = action.payload.openAddToHomePopUp;
    },
    updateMayBeLater: (state, action) => {      
      state.mayBeLater = action.payload.mayBeLater;
    }
  },
  extraReducers: (builder) => {
    builder //clears everything on 401
      .addCase(clearStore, (state) => {
        return {
          ...globalSliceInitialState,
          athsEvent: state.athsEvent,
        };
      })
      .addDefaultCase((state, action) => { })
  }
})

export const {
  setLoader,
  saveAthsEvent,
  callGetTxnFee,
  callGetTxnFeeSuccess,
  callGetTxnFeeFailed,
  callGetLOB,
  callGetLOBSuccess,
  callGetLOBFailed,
  callProcessTrxn,
  setOnline,
  setCustomLoader,
  callGetBetaFeatures,
  callGetBetaFeaturesSuccess,
  callGetBetaFeaturesFailed,
  callGetRecentContactsRecharge,
  callGetRecentContactsBundle,
  callGetRecentContactsSendMoney,
  setHistoryData,
  setRecentNumberName,
  setCheckLobData,
  resetCheckLobData,
  resetHistoryData,
  resetRecentNumberName,
  callFetchFavouritesSuccess,
  callFetchFavouritesFailed,
  callFetchFavourites,
  callAddFavourite,
  setUpFeaturesStore,
  callDeleteFavourite,
  callEditFavourite,
  fetchAllFavourites,
  fetchBetaUsers,
  fetchBetaUsersSuccess,
  callProcessTxnV2,
  callFetchTransactionFee,
  callCheckTransactionLimit,
  callAMUserInfo,
  setApiError,
  updateShowIOSScreen,
  updateAddToHomePopUp,
  updateMayBeLater
} = globalSlice.actions;

export default globalSlice.reducer;